<template>
    <div class="income-restaurant-base-table box">
        <table class="income-restaurant-base-table__table income-restaurant-base-table__table_striped income-restaurant-base-table__table_hover">
            <col class="income-restaurant-base-table__column"
                v-for="column in columns"
                :key="column.id"
                :class="`income-restaurant-base-table__column_${column.id}`"
            >
            <thead>
                <tr class="income-restaurant-base-table__row income-restaurant-base-table__row_head">
                    <th class="income-restaurant-base-table__cell income-restaurant-base-table__cell_head" scope="col"
                        v-for="column in columns"
                        :key="column.id"
                    >
                        <div class="income-restaurant-base-table__cell-content income-restaurant-base-table__cell-content_columns"
                            :class="`income-restaurant-base-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-restaurant-base-table__value">{{column.title}}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="income-restaurant-base-table__row income-restaurant-base-table__row_body"
                    v-for="row in rows"
                    :key="row.id"
                    :class="{ 'income-restaurant-base-table__row_pinned': row.id === 'total' }"
                >
                    <td class="income-restaurant-base-table__cell"
                        v-for="column in columns"
                        :key="column.id"
                        :class="{ 'income-restaurant-base-table__cell_title': column.id === 'title' }"
                    >
                        <div class="income-restaurant-base-table__cell-content income-restaurant-base-table__cell-content_rows"
                            :class="`income-restaurant-base-table__cell-content_${column.alignment}`"
                        >
                            <span class="income-restaurant-base-table__value">{{format(column, row)}}</span>
                            <template v-if="row.id !== 'total' && column.id !== 'title'">
                                <percentage v-if="['value', 'orders_count'].includes(column.id)"
                                    class="income-restaurant-base-table__value"
                                    :part="row[column.id]"
                                    :whole="rows[rows.length - 1][column.id]"
                                />
                                <difference v-else-if="column.id === 'average_bill'"
                                    class="income-restaurant-base-table__value"
                                    :primaryValue="row[column.id]"
                                    :compareValue="rows[rows.length - 1][column.id]"
                                    mode="subtraction"
                                    filter="currencyIntFormat"
                                />
                            </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import Percentage from "@/components/Percentage";
    import Difference from "@/components/Difference";

    export default {
        name: "IncomeRestaurantBaseTable",
        components: {
            Percentage,
            Difference,
        },
        props: {
            columns: {
                type: Array,
                required: true
            },
            rows: {
                type: Array,
                required: true
            }
        },
        computed: {
        },
        methods: {
            format(column, row) {
                if (!this.$options.filters[column.filter]) {
                    return row[column.id];
                }

                return this.$options.filters[column.filter](row[column.id]);
            }
        },
    };
</script>

<style lang="scss">
    .income-restaurant-base-table {
        padding: 16px;
        overflow-x: auto;
    }
    .income-restaurant-base-table__table {
        width: 100%;
    }
    .income-restaurant-base-table__column {
        &_title {
            width: 25%;
        }

        &_dish_group {
            width: 18%;
        }
    }
    .income-restaurant-base-table__row {
        &_body {
            .income-restaurant-base-table__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .income-restaurant-base-table__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .income-restaurant-base-table__cell {
        font-size: 20px;
        padding: 12px 8px;

        &_head {
            padding: 8px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_tar {
            text-align: right;
        }
    }
    .income-restaurant-base-table__cell-content {
        display: grid;
        grid-gap: 5px;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.income-restaurant-base-table__cell-content_left {
                justify-content: start;
            }

            &.income-restaurant-base-table__cell-content_right {
                justify-content: end;
                text-align: right;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.income-restaurant-base-table__cell-content_left {
                justify-items: start;
            }

            &.income-restaurant-base-table__cell-content_right {
                justify-items: end;
            }
        }
    }
    .income-restaurant-base-table__value {
        &_icon {
            width: 16px;
            height: 16px;
        }
    }
</style>
