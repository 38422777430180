<template>
    <div class="income-restaurant">
        <router-link class="income-restaurant__link income-restaurant__link_back link link_red"
            ref="backLink"
            v-if="userRestaurants.ids.length > 1"
            :to="{ name: 'IncomeTotal' }"
        >
            Вернуться к общему отчету по доходам
        </router-link>
        <income-settings class="income-restaurant__settings"
            :showRestaurantsFilter="id === 'total'"
        />
        <spinner v-if="status === 'loading'" class="income-restaurant__spinner" />
        <error-message v-else-if="status === 'error'" class="income-restaurant__error-message">
            Не удалось загрузить данные отчета "Доходы".
        </error-message>
        <p v-else-if="status === 'no-restaurants'">Выберите хотя бы один ресторан.</p>
        <section v-else-if="status === 'success'" class="income-restaurant__data">
            <h2 class="income-restaurant__data-heading sr-only">Данные отчета "Доходы"</h2>
            <template v-if="modeId === 'base'">
                <div class="income-restaurant__box income-restaurant__box_charts box">
                    <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_first-of-three">
                        <figure class="income-restaurant__chart">
                            <figcaption class="income-restaurant__chart-title h2">Распределение выручки</figcaption>
                            <highcharts class="income-restaurant__chart-graph" :options="chartOptions('value')" />
                        </figure>
                    </div>
                    <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_second-of-three">
                        <figure class="income-restaurant__chart">
                            <figcaption class="income-restaurant__chart-title h2">Распределение заказов</figcaption>
                            <highcharts class="income-restaurant__chart-graph" :options="chartOptions('orders_count')" />
                        </figure>
                    </div>
                    <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_third-of-three">
                        <figure class="income-restaurant__chart">
                            <figcaption class="income-restaurant__chart-title h2">Средний чек</figcaption>
                            <highcharts class="income-restaurant__chart-graph" :options="averageBillChartOptions" />
                        </figure>
                    </div>
                </div>
                <income-restaurant-base-table
                    :columns="serviceTypeIncomeColumns"
                    :rows="reportRestaurantServiceTypeIncomes"
                />
            </template>
            <template v-else-if="modeId === 'compareOtherPeriod'">
                <p class="income-restaurant__error" v-if="!compareIncome">Выберите период сравнения.</p>
                <template v-else>
                    <div class="income-restaurant__box income-restaurant__box_charts box">
                        <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_first-of-three">
                            <figure class="income-restaurant__chart">
                                <figcaption class="income-restaurant__chart-title h2">Распределение выручки</figcaption>
                                <highcharts class="income-restaurant__chart-graph" :options="chartOptions('value')" />
                            </figure>
                        </div>
                        <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_second-of-three">
                            <figure class="income-restaurant__chart">
                                <figcaption class="income-restaurant__chart-title h2">Распределение заказов</figcaption>
                                <highcharts class="income-restaurant__chart-graph" :options="chartOptions('orders_count')" />
                            </figure>
                        </div>
                        <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_third-of-three">
                            <figure class="income-restaurant__chart">
                                <figcaption class="income-restaurant__chart-title h2">Средний чек</figcaption>
                                <highcharts class="income-restaurant__chart-graph" :options="averageBillChartOptions" />
                            </figure>
                        </div>
                    </div>
                    <income-compare-table
                        v-for="reportRestaurantIncomeParameter in reportRestaurantIncomeParameters"
                        :key="reportRestaurantIncomeParameter.id"
                        :columns="getIncomeColumns(reportRestaurantIncomeParameter.id)"
                        :reportRow="reportRestaurantIncomeParameter"
                        :compareRow="findRestaurantIncomeParameter(compareRestaurantIncomeParameters, reportRestaurantIncomeParameter)"
                    />
                </template>
            </template>
            <template v-else-if="modeId === 'comparePlan'">
                <div class="income-restaurant__chart-wrapper income-restaurant__chart-wrapper_full-width" key="compare-plan-graph">
                    <figure class="income-restaurant__chart">
                        <figcaption class="income-restaurant__chart-title h2">Общая выручка</figcaption>
                        <highcharts class="income-restaurant__chart-graph" :options="comparePlanChartOptions" />
                    </figure>
                </div>
                <income-compare-table
                    :columns="planColumns"
                    :reportRow="reportRestaurantPlanActual"
                    :compareRow="reportRestaurantPlanPrediction"
                />
            </template>
            <template v-else-if="modeId === 'dynamic'">
                <p class="income-restaurant__error" v-if="!dynamicIncome">Выберите период динамики.</p>
                <income-dynamic-table v-else
                    v-for="reportRestaurantIncomeParameter in reportRestaurantIncomeParameters"
                    :key="reportRestaurantIncomeParameter.id"
                    :columns="getIncomeColumns(reportRestaurantIncomeParameter.id)"
                    :reportRow="reportRestaurantIncomeParameter"
                    :dynamicRow="findRestaurantIncomeParameter(dynamicRestaurantIncomeParameters, reportRestaurantIncomeParameter)"
                />
            </template>
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { isDaterangeSet, daterangeText, daterangeKey } from "@/helpers/daterange";
    import { restaurantsKey } from "@/helpers/restaurants";
    import * as filters from "@/helpers/filters";
    import IncomeSettings from "./common/IncomeSettings";
    import IncomeRestaurantBaseTable from "./income-restaurant/IncomeRestaurantBaseTable";
    import IncomeCompareTable from "./common/IncomeCompareTable";
    import IncomeDynamicTable from "./common/IncomeDynamicTable";

    let totalTitle = "Доходы по всем ресторанам";

    export default {
        name: "IncomeRestaurant",
        props: {
            id: {
                type: String,
                required: true
            },
        },
        components: {
            IncomeSettings,
            IncomeRestaurantBaseTable,
            IncomeCompareTable,
            IncomeDynamicTable,
        },
        data() {
            return {
                status: "loading",
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.income.compareDaterange,
                dynamicDaterange: state => state.income.dynamicDaterange,
                modeId: state => state.income.modeId,
                sorting: state => state.income.sorting,
                data: state => state.income.data,
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
            ]),
            actualReportRestaurants() {
                if (this.id === "total") {
                    return this.reportRestaurants;
                }

                return [this.userRestaurants?.byId?.[this.id]];
            },
            actualReportRestaurantsKey() {
                if (this.id === "total") {
                    return this.reportRestaurantsKey;
                }

                return restaurantsKey([this.userRestaurants?.byId?.[this.id]]);
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                if (this.modeId === "dynamic" && isDaterangeSet(this.dynamicDaterange)) {
                    dateranges.push(this.dynamicDaterange);
                }

                return dateranges;
            },
            requiredIncomes() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.actualReportRestaurantsKey}`]);
            },
            serviceTypes() {
                return [
                    {
                        id: "delivery",
                        title: "Доставка",
                    },
                    {
                        id: "hall",
                        title: "Зал",
                    },
                    {
                        id: "take",
                        title: "С собой",
                    },
                    {
                        id: "pickup",
                        title: "Самовынос",
                    },
                    {
                        id: "total",
                        title: "Все",
                    },
                ];
            },
            incomeParameters() {
                return [
                    {
                        id: "value",
                        title: "Выручка",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "orders_count",
                        title: "Заказы/Гости",
                        alignment: "right",
                        filter: "intFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "average_bill",
                        title: "Средний чек",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                ];
            },
            serviceTypeIncomeColumns() {
                return [
                    {
                        id: "title",
                        title: "Тип обслуживания",
                        alignment: "left",
                    },
                    ...this.incomeParameters,
                ];
            },
            planColumns() {
                return [
                    {
                        id: "title",
                        title: "Источник",
                        alignment: "left",
                    },
                    {
                        id: "total",
                        title: "Общая выручка",
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                    {
                        id: "prediction",
                        title: this.predictionTitle,
                        alignment: "right",
                        filter: "currencyIntFormat",
                        difference: {
                            good: "positive",
                        },
                    },
                ];
            },
            reportIncome() {
                return this.data[`${daterangeKey(this.reportDaterange)}-${this.actualReportRestaurantsKey}`];
            },
            predictionTitle() {
                return this.reportIncome?.prediction_title;
            },
            reportRestaurant() {
                if (this.id === "total") {
                    return this.reportIncome?.total;
                }

                return this.reportIncome?.restaurants?.find(({ id }) => id === this.id);
            },
            reportRestaurantServiceTypeIncomes() {
                return this.serviceTypes?.map(({ id, title }) => ({
                    id,
                    title,
                    ...this.reportRestaurant?.income?.[id],
                }));
            },
            reportRestaurantIncomeParameters() {
                return this.incomeParameters?.map(incomeParameter => ({
                    id: incomeParameter.id,
                    caption: incomeParameter.title,
                    title: daterangeText(this.reportDaterange),
                    ...Object.fromEntries(this.serviceTypes?.map(serviceType => ([
                        serviceType.id,
                        this.reportRestaurant?.income?.[serviceType.id]?.[incomeParameter.id]
                    ]))),
                }));
            },
            reportRestaurantPlanActual() {
                return {
                    title: daterangeText(this.reportDaterange),
                    total: this.reportRestaurant?.income?.total?.value,
                    prediction: this.reportRestaurant?.prediction?.total,
                };
            },
            reportRestaurantPlanPrediction() {
                return {
                    title: "По плану выручки",
                    total: this.reportRestaurant?.plan?.sum,
                    prediction: this.reportRestaurant?.plan?.sum_prediction,
                };
            },
            compareIncome() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[`${daterangeKey(this.compareDaterange)}-${this.actualReportRestaurantsKey}`];
                }
            },
            compareRestaurant() {
                if (this.id === "total") {
                    return this.compareIncome?.total;
                }

                return this.compareIncome?.restaurants?.find(({ id }) => id === this.id);
            },
            compareRestaurantIncomeParameters() {
                return this.incomeParameters?.map(incomeParameter => {
                    let newIncomeParameter = {
                        id: incomeParameter.id,
                        title: daterangeText(this.compareDaterange),
                    };

                    if (this.compareRestaurant) {
                        return {
                            ...newIncomeParameter,
                            ...Object.fromEntries(this.serviceTypes?.map(serviceType => ([
                                serviceType.id,
                                this.compareRestaurant?.income?.[serviceType.id]?.[incomeParameter.id]
                            ]))),
                        };
                    };

                    return newIncomeParameter;
                });
            },
            dynamicIncome() {
                if (isDaterangeSet(this.dynamicDaterange)) {
                    return this.data[`${daterangeKey(this.dynamicDaterange)}-${this.actualReportRestaurantsKey}`];
                }
            },
            dynamicRestaurant() {
                if (this.id === "total") {
                    return this.dynamicIncome?.total;
                }

                return this.dynamicIncome?.restaurants?.find(({ id }) => id === this.id);
            },
            dynamicRestaurantIncomeParameters() {
                return this.incomeParameters?.map(incomeParameter => {
                    let newIncomeParameter = {
                        id: incomeParameter.id,
                        title: daterangeText(this.dynamicDaterange),
                    };

                    if (this.dynamicRestaurant) {
                        return {
                            ...newIncomeParameter,
                            ...Object.fromEntries(this.serviceTypes?.map(serviceType => ([
                                serviceType.id,
                                this.dynamicRestaurant?.income?.[serviceType.id]?.[incomeParameter.id]
                            ]))),
                        };
                    };

                    return newIncomeParameter;
                });
            },
            averageBillChartOptions() {
                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#eb4f3b", "#f4755c", "#fb9780", "#ffb7a5", "#e0121a"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: [daterangeText(this.reportDaterange)],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: null
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },
                    tooltip: {
                        pointFormatter() {
                            return `${this.series.name}: ${filters.currencyIntFormat(this.y)}`;
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return filters.currencyIntFormat(this.y);
                                }
                            }
                        }
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    credits: {
                        enabled: false
                    },
                    series: this.serviceTypes.map(({ id, title }) => ({
                        name: title,
                        data: [Math.round(this.reportRestaurant?.income?.[id]?.average_bill)]
                    })),
                };

                if (this.modeId === "compareOtherPeriod" && this.compareRestaurant) {
                    options.xAxis.categories.push(daterangeText(this.compareDaterange));

                    this.serviceTypes.forEach(({ id }, index) => {
                        options.series[index].data.push(Math.round(this.compareRestaurant?.income?.[id]?.average_bill));
                    });
                }

                return options;
            },
            comparePlanChartOptions() {
                return {
                    chart: {
                        type: "bar"
                    },
                    colors: ["#e0121a", "#eb4f3b", "#f4755c", "#fb9780"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: [
                            "Общая выручка",
                            `Прогноз выручки<br> ${this.predictionTitle}`
                        ],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: null,
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true,
                                formatter() {
                                    return filters.currencyIntFormat(this.y);
                                }
                            }
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [{
                        name: this.reportRestaurantPlanActual?.title,
                        data: [
                            this.reportRestaurantPlanActual?.total,
                            this.reportRestaurantPlanActual?.prediction,
                        ]
                    }, {
                        name: this.reportRestaurantPlanPrediction?.title,
                        data: [
                            this.reportRestaurantPlanPrediction?.total,
                            this.reportRestaurantPlanPrediction?.prediction,
                        ]
                    }]
                };
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
            ]),
            ...mapActions([
                "requestIncomes"
            ]),
            showReport() {
                this.status = "success";
            },
            loadIncomes() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.actualReportRestaurantsKey}`])
                );

                this.requestIncomes({
                    dateranges: daterangesWithoutData,
                    restaurants: this.actualReportRestaurants,
                    restaurantsKey: this.actualReportRestaurantsKey,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            getIncomeColumns(incomeParameterId) {
                let { alignment, filter, difference } = this.incomeParameters?.find(({ id }) => id === incomeParameterId);

                return [
                    {
                        id: "title",
                        title: this.modeId === "compareOtherPeriod" ? "Период" : "Источник",
                        alignment: "left",
                    },
                    ...this.serviceTypes?.map(serviceType => ({ ...serviceType, alignment, filter, difference }))
                ];
            },
            findRestaurantIncomeParameter(restaurantIncomeParameters, incomeParameter) {
                return restaurantIncomeParameters?.find(({id }) => id === incomeParameter.id);
            },
            chartOptions(parameter) {
                let chartServiceTypes = this.serviceTypes.filter(({ id }) => id !== "total");

                let options = {
                    chart: {
                        type: "column",
                    },
                    colors: ["#e0121a", "#eb4f3b", "#f4755c", "#fb9780"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: [daterangeText(this.reportDaterange)]
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: "bold"
                            }
                        }
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    tooltip: {},
                    plotOptions: {
                        column: {
                            stacking: "normal",
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    series: chartServiceTypes.map(({ id, title }) => ({
                        name: title,
                        data: [Math.round(this.reportRestaurant?.income?.[id]?.[parameter])]
                    })),
                };

                if (this.modeId === "compareOtherPeriod" && this.compareRestaurant) {
                    options.xAxis.categories.push(daterangeText(this.compareDaterange));

                    chartServiceTypes.forEach(({ id }, index) => {
                        options.series[index].data.push(Math.round(this.compareRestaurant?.income?.[id]?.[parameter]));
                    });
                }

                switch (parameter) {
                    case "value":
                        options.yAxis.stackLabels.formatter = function() {
                            return filters.currencyIntFormat(this.total);
                        };
                        options.plotOptions.column.dataLabels.formatter = function() {
                            return filters.currencyIntFormat(this.y);
                        };
                        options.tooltip.pointFormatter = function() {
                            return `${this.series.name}: ${filters.currencyIntFormat(this.y)} из ${filters.currencyIntFormat(this.total)} (${filters.percentFloatFormat(this.y / this.total)})`;
                        }
                        break;
                    case "orders_count":
                        options.yAxis.stackLabels.formatter = function() {
                            return filters.intFormat(this.total);
                        };
                        options.plotOptions.column.dataLabels.formatter = function() {
                            return filters.intFormat(this.y);
                        };
                        options.tooltip.pointFormatter = function() {
                            return `${this.series.name}: ${filters.intFormat(this.y)} из ${filters.intFormat(this.total)} (${filters.percentFloatFormat(this.y / this.total)})`;
                        }
                        break;
                }

                return options;
            }
        },
        created() {
            if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({
                    name: "Error401",
                    params: {
                        requestedResource: `Ресторан ${this.id}`
                    }
                });
            } else {
                if (this.id === "total" && this.title !== totalTitle) {
                    this.setTitle(totalTitle);
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} "${restaurantTitle}"`);
                    }
                }

                if (this.id === "total" && this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (this.requiredIncomes.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadIncomes();
                }
            }
        },
        watch: {
            requiredIncomes(requiredIncomes) {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredIncomes.every(Boolean)) {
                    this.loadIncomes();
                } else {
                    this.showReport();
                }
            }
        },
    }
</script>

<style lang="scss">
    .income-restaurant__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .income-restaurant__data {
        display: grid;
        grid-gap: 16px;
    }
    .income-restaurant__box {
        padding: 16px;

        &_charts {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;

            @include desktop {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
    .income-restaurant__chart-wrapper {
        &_full-width {
            @include desktop {
                grid-column: 1/-1;
            }
        }

        &_first-of-three {
            @include desktop {
                grid-column: 1/3;
            }
        }

        &_second-of-three {
            @include desktop {
                grid-column: 3/5;
            }
        }

        &_third-of-three {
            @include desktop {
                grid-column: 5/7;
            }
        }
    }
    .income-restaurant__chart-title {
        margin-bottom: 10px;
    }
</style>
